.container {
  width: 300px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 70px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
}
.logo {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.logo a {
  height: 100%;
  padding: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  height: 100%;
}
.nav_items_container {
  padding: 1.5rem;
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: auto;
}
.nav_item_container {
  margin-bottom: 1rem;
}
.nav_item_container h5 {
  color: var(--p);
  text-transform: uppercase;
}
.nav_item {
  padding: 0.3rem 0 0 0.5rem;
}
.nav_item a,
.dropdown_trigger p {
  color: #000;
  font-weight: 700;
}
.nav_item a.single_active {
  color: var(--p);
}
.dropdown_trigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dropdown_trigger_padding {
  padding-bottom: 0.3rem;
}
.arrow_container {
  width: 20px;
  height: 20px;
}
.dropdown_container {
  padding-left: 0.5rem;
  position: relative;
}
.dropdown_container::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #cdcdcd;
}
.dropdown_container a {
  display: block;
  padding: 0.5rem;
  font-weight: 500;
  color: #878787;
  padding-bottom: 0rem;
  transition: all 0.2s linear;
}
.dropdown_container a:hover {
  color: #000;
}
.dropdown_container a:first-child {
  padding-top: 0rem;
}
.dropdown_container a.active {
  background-color: var(--p_l);
  color: var(--p);
  padding: 0.3rem 0.8rem;
  border-radius: 0.3rem;
  font-weight: 700;
}

.arrow {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
}
.arrow span {
  top: 6px;
  position: absolute;
  width: 9px;
  height: 1px;
  background-color: #000;
  display: inline-block;
  transition: all 0.2s ease;
}
.arrow span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}
.arrow span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}
.arrow.active_arrow span:first-of-type {
  transform: rotate(-45deg);
}
.arrow.active_arrow span:last-of-type {
  transform: rotate(45deg);
}
@media screen and (max-width: 710px) {
  .container {
    top: 70px;
    left: -300px;
    background-color: #fff;
    z-index: 10;
  }
  .logo {
    display: none;
  }
}
