.container {
  padding: 0rem var(--page_padding);
  width: calc(100% - 300px);
  height: 70px;
  background-color: #fff;
  box-shadow: var(--sbs);
  position: fixed;
  top: 0;
  left: 300px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar_trigger button {
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
}
.shortcuts ul {
  display: flex;
}
.shortcuts ul li {
  margin-right: 1rem;
}
.shortcuts ul li:last-child {
  margin-right: 0rem;
}
.shortcuts ul li a,
.sidebar_trigger button {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
}
.shortcuts ul li a img,
.sidebar_trigger button img {
  width: 22px;
  height: 22px;
}
.shortcuts ul li a .user {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logo img {
  visibility: hidden;
}
.sidebar_trigger .display {
  display: none;
}
.visible {
  left: 0;
}
@media screen and (max-width: 710px) {
  .container {
    width: 100%;
    left: 0px;
  }
  .logo img {
    visibility: visible;
    height: 30px;
    display: block;
  }
  .sidebar_trigger button {
    visibility: visible;
  }
  .shortcuts ul li {
    display: none;
  }
  .shortcuts ul li:last-child {
    display: block;
  }
}
